.office-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.office {
    width: 100%;
}

.office-info {
    position: relative;
    top: 0;
    width: 100%;
    min-height: 500px;
    height: min(86vh, calc(100vh - 100px));
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}

.office-info-side {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 32px;
}

.office-info-front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 26px;
}

.office-info-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.7);
    background-color: white;
}

.office-info-back img {
    width: 100%;
    height: 100%;
    z-index: -10;
    object-fit: cover;
}

.office-content {
    width: 90%;
    max-width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.office-info-front .office-content{
    border: 2px solid #0F62AF;
    border-radius: 20px;
}

.office-title {
    width: 100%;
    font-size: 0.9em;
    margin-bottom: 20px;
}

.office-address {
    width: 100%;
    margin-bottom: 20px;
    font-size: 0.7em;
}

.office-address a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    font-size: 0.9em;
}

@media (min-width: 1000px) {
    .office-address a:hover {
        text-decoration: underline solid 1px;
        transition: 0.3s;
    }
}



.office-phone {
    width: 100%;
    margin-bottom: 20px;
    font-size: 0.7em;
}

.office-phone a {
    text-decoration: none;
    color: inherit;
    transition: 0.3s;
    font-size: 0.9em;
    
}

@media (min-width: 1000px) {
    .office-phone a:hover {
        transition: 0.3s;
        text-decoration: underline solid 1px;
    }
}

.office-hours {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.hours-title {
    font-size: 0.7em;
}

.hours-note {
    margin: 0;
    font-size: 0.5em;
}

.office-day {
    font-size: 0.6em;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.office-info-side img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}