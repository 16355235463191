
.header-cont {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 0px;
    height: 14vh;
    min-height: 100px;
    max-height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: white;
    color: black; 
    z-index: 100;
    box-shadow: 0 1px 2px;
}

.header {
    font-size: 20px;
    height: 90%;
    width: 95%;
    max-width: 2375px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.header-title {
    height: 70%;
    background-color: transparent;
}

.header-title img{
    height: 100%;
}

.header-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    background-color: transparent;
}

.active-header-itm {
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #0F62AF;
}

.header-itm {
    text-decoration: none;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: inherit;
}

@media (min-width: 1000px) {
    .header-itm:hover .header-link{
        transition-duration: .5s;
        color: #84e4f0;
    }
}



.header-link{
    width: 100%;
    color: inherit;
    text-decoration: none;
    font-size: 1em;
    text-align: center;
}

.header-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70%;
}

.bb-logo {
    background-color: white;
    padding: 0;
    font-size: 0;
    height: fit-content;
    margin-right: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.bb-logo a {
    padding: 0;
    margin: 0;
    font-size: 0;
    width: 100%;
}

.bb-logo a img {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 0;
    
}

.header-rank {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70%;
}

.header-rank img {
    height: 100%;
    border-radius: 100px;
}


.header-cont-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    font-size: 0px;
    height: 14vh;
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: white;
    color: black; 
    z-index: 100;
    box-shadow: 0 0.1px 2px;
}

.header-mobile {
    font-size: min(max(16px, 2vw), 32px);
    height: 90%;
    width: 90%;
    max-width: calc(0.95 * 2500px);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.header-rank-mobile {
    width: 10%;
    min-width: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header-rank-mobile img {
    width: 50%;
    min-width: 40px;
}

.header-title-mobile {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-title-mobile img{
    height: 100%;
}

.header-menubox {
    width: 10%;
    min-width: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header-menubox img {
    width: 50%;
    min-width: 40px;
}

.header-menu-mobile-none {
    display: none;
}

@keyframes menuappear {
    from {left: 100vw}
    to {left: 0vw}
}

@-webkit-keyframes menuappear {
    from {left: 100vw}
    to {left: 0vw}
}

@keyframes menudisappear {
    from {left: 0vw}
    to {left: 100vw}
}

@-webkit-keyframes menudisappear {
    from {left: 0vw}
    to {left: 100vw}
}

.header-menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: white;
    animation: menuappear 1s 1;
    -webkit-animation: menuappear 1s 1;
    z-index: 100;
}

.header-menu-mobile-invisible {
    position: fixed;
    top: 0;
    left: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: white;
    animation: menudisappear 1s 1;
    -webkit-animation: menudisappear 1s 1;
    z-index: 100;
}

@keyframes exitspinopen {
    from {transform: rotate(1440deg)}
    to {transform: rotate(0)}
}

@keyframes exitspinclose {
    from {transform: rotate(0)}
    to {transform: rotate(1440deg)}
}

@-webkit-keyframes exitspinopen {
    from {-webkit-transform: rotate(1440deg)}
    to {-webkit-transform: rotate(0)}
}

@-webkit-keyframes exitspinclose {
    from {-webkit-transform: rotate(0)}
    to {-webkit-transform: rotate(1440deg)}
}

.menu-exit-header-menu-mobile {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: exitspinopen 1s 1;
    -webkit-animation: exitspinopen 1s 1;
}

.menu-exit-header-menu-mobile-invisible {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: exitspinclose 1s 1;
    -webkit-animation: exitspinclose 1s 1;
}

.menu-exit-header-menu-mobile .menu-icon {
    width: 100%;
    height: 100%;
}

.menu-exit-header-menu-mobile-invisible .menu-icon {
    width: 100%;
    height: 100%;
}

.mobile-items-container {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.active-header-itm-mobile {
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0F62AF;
}

.header-itm-mobile {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    font-size: inherit;
}


.link-mobile{
    width: 100%;
    color: inherit;
    text-decoration: none;
    font-size: 20px;
    text-align: center;
}
