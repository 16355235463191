.home-container {
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home {
    width: 100%;
}

.landing {
    position: relative;
    width: 100%;
    height: 90vh;
    min-height: 600px;
}

.landing-img-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.6);
}

.landing-img-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100;

}

.landing video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    z-index: -10;
}

.landing-overlay {
    position: absolute;
    width: 100%;
    height: max(86vh, 100px);
    bottom: 0;
    left: 0;
    color: white;
}

.format-overlay {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-container {
    width: 95%;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
}

@media (max-width: 1000px) {
    .overlay-container {
        font-size: 24px;
    }
}

.landing-overlay-text {
    font-size: 1em;
    width: fit-content;
}

.landing-overlay-request-container {
    transition: 0.2s;
    color: inherit;
    text-decoration: none;
    padding: 5px 25px;
    background-color: #0F62AF;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 100px;
    font-size: 0.7em;
}

@media (min-width: 1000px) {
    .landing-overlay-request-container:hover {
        transition: 0.2s;
        font-size: 0.72em;
    }
}

