#block-welcome {
    position: relative;
    margin-top: 20px;
    margin-bottom: 5px;
    width: 95%;
    height: 98%;
    border-radius: 20px;
    overflow: hidden;
    transition: 0.4s;
}


#block-image-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
}

#block-container {
    position: relative;
    margin-top: 20px;
    margin-bottom: 5px;
    width: 95%;
    height: 98%;
    border-radius: 20px;
    overflow: hidden;
    transition: 0.4s;
}

@media (min-width: 1000px) {
    #block-container:hover {
        width: 96%;
        height: 100%;
        box-shadow: 3px 3px 5px black;
        transition: 0.4s;
    }
}

#block-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);

}


#block-image-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
}

#block-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#block-center {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overview-header {
    font-size: 36px;
    margin: 50px 70px;
    text-decoration: none;
    text-align: center;
    color: white;
    text-align: center;
    margin-bottom: 50px;
}



#mobile-welcome-text {
    width: 90%;
    color: white;
    font-size: 18px;
}

@media (max-width: 800px) {
    #block-content {
        justify-content: center;
        align-items: end;
    }

    .overview-header {
        margin: 0;
        width: 100%;
        background-color: #0F62AF;
        font-size: 26px;
        text-decoration: none;
        text-align: center;
        color: white;
        text-align: center;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

