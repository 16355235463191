
.service-info {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
}

.service-hidden {
    display: none;
}

.service-display {
    position: relative;
    width: 80%;
    min-width: 350px;
    height: 80%;
    overflow: scroll;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    font-size: 32px;
    padding-bottom: 100px;
    border-radius: 30px;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}

.service-display::-webkit-scrollbar {
    display: none;
}

@media (max-width: 700px) {
    .service-display {
        font-size: 20px;
    }
}


.service-info-x {
    width: 100%;
    height: 70px;
    min-height: 70px;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    display: flex;
    justify-content: end;
}

.service-info-x .service-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 25px;
    margin-top: 25px;
}

.service-info-intro {
    width: 80%;
    font-size: 1em;
    margin-bottom: 50px;
}


.service-info-underline {
    width: 100%;
    height: 2px;
    background-color: #0F62AF;
}

.service-info-picture {
    width: 300px;
    max-width: 90%;
    margin-bottom: 40px;
}


.service-info-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    border: 3px solid #0F62AF;
}


.service-blurb {
    width: 80%;
    font-size: 0.85em;
}

.service-blurb div {
    margin-bottom: 30px;
    font-size: 0.90em;
}

.service-blurb h2 {
    font-size: 1.2em;
    margin-bottom: 30px;
    font-weight: normal;
}
