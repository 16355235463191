.site-overview {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 100px;
}

.overview-section-summary {
    width: 100%;
    height: fit-content;
    padding: 100px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.overview-side {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}


.overview-section {
    width: 100%;
    height: 80vh;
    min-height: 500px;
    max-height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



#overview-logo {
    width: 80%;
}

.welcome-text {
    font-size: 1em;
    width: 80%;
}


.overview-full-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
