.footer {
    object-position: center;
    width: 100vw;
    background-color: #0F62AF;
    color: white;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    min-height: fit-content;
}

.footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 30px;
}

.foot-link {
    min-width: 200px;
    text-align: center;
    text-decoration: none;
    color: white;
    padding: 5px 10px;
    margin: 5px;
    transition: all 0.3s ease-in-out;
}


.footer-copyright {
    color: white;
}


