

.staff-bio {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
}

.staff-hidden {
    display: none;
}

.staff-display {
    position: relative;
    width: 80%;
    min-width: 350px;
    height: 70%;
    overflow: scroll;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    font-size: 32px;
    padding-bottom: 100px;
    border-radius: 30px;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}

.staff-display::-webkit-scrollbar {
    display: none;
}

@media (max-width: 700px) {
    .staff-display {
        font-size: 20px;
    }
}


.staff-bio-x {
    width: 100%;
    height: 70px;
    min-height: 70px;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    display: flex;
    justify-content: end;
}

.staff-bio-x .staff-bio-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 25px;
    margin-top: 25px;
}

.staff-bio-intro {
    width: 80%;
    font-size: 1em;
    margin-bottom: 50px;
}


.staff-bio-underline {
    width: 100%;
    height: 2px;
    background-color: #0F62AF;
}

.staff-bio-picture {
    height: 350px;
    width: 250px;
    margin-bottom: 40px;
}

.staff-bio-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    border: 3px solid #0F62AF;
}

.staff-bio-blurb {
    width: 85%;
    font-size: 0.75em;
}

.staff-bio-blurb div {
    margin-bottom: 30px
}



.dentist-qualification {
    width: 80%;
    font-size: 0.9em;
}

.dentist-qualification-category {
    margin-bottom: 10px;
}

.dentist-qualification-list {
    font-size: 0.8em;
}

.dentist-qualification-item {
    margin-bottom: 10px;
}

.dentist-qualification-name {
    font-size: 1em;
}

.dentist-qualification-specification {
    font-size: 0.80em;
    margin-left: 20px;
}
