.staff-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-size: 32px;
}

@media (max-width: 700px) {
    .staff-container {
        font-size: 24px;
    }
}

.staff-image {
    width: 100%;
}

.staff-title {
    width: 90%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}



.staff-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    max-width: 1400px;
    gap: 50px;
    margin-bottom: 100px;
}


.staff-profile {
    width: 250px;
    height: 350px;
    margin: 5px;
}

.staff-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 240px;
    height: 340px;
    margin: 5px;
    background-color: #0F62AF;
    border: 3px solid #0F62AF;
    overflow: hidden;
    border-radius: 20px;
    transition: 0.3s;
}

@media (min-width: 1000px) {
    .staff-card:hover {
        cursor: pointer;
        width: 250px;
        height: 350px;
        margin: 0;
        transition: 0.3s;
        box-shadow: 1px 1px 3px black;
    }
}



.staff-picture {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    overflow: hidden;
}

.staff-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.staff-info {
    position: absolute;
    bottom: 0;
    background-color: #0F62AF;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.staff-name {
    color: white;
    font-size: 0.7em;
    margin-bottom: 10px;
}

.staff-learn-more {
    color: #0F62AF;
    padding: 3px 7px;
    width: fit-content;
    font-size: 0.5em;
    background-color: white;
    border-radius: 100px;
}

