.forms-container {
    width: 100%;
    min-height:86vh;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}



.forms {
    width: 60%;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    margin-top: 200px;
    margin-bottom: 100px;
    padding: 30px;
    border-radius: 20px;
    border: 3px solid #0F62AF;
}

@media (max-width: 700px) {
    .forms {
        font-size: 18px;
    }
}

.forms-title {
    font-size: 1em;
    margin-bottom: 10px;
}

.forms-descriptions {
    font-size: 0.9em;
    margin-bottom: 20px;
    text-align: center;
}

.forms-link {
    margin-bottom: 20px;
    padding: 5px 5px;
    font-size: 0.9em;
    color: white;
    transition: 0.3s;
    min-width: 250px;
    width: 40%;
    text-decoration: none;
    background-color: #0F62AF;
    border-radius: 50px;
    text-align: center;

}

@media (min-width: 1000px) {
    .forms-link:hover {
        box-shadow: 2px 2px 3px black;
        width: 41%;
    }
}


