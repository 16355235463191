.services-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 32px;
}

@media (max-width: 1000px) {
    .services-container {
        font-size: 24px;
    }
}

.services-title {
    width: 100%;
    height: min(86vh, calc(100vh - 100px));
    display: flex;
    align-items: center;
    flex-direction: column;
}

.services-overlay-container {
    position: relative;
    width: 100%;
    height: calc(100% - 100px);
}

.services-overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.7);
}

.services-overlay-image img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
}

.services-title-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1em;
    text-align: center;
}


.services-content {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 50px;
}

@media (max-width: 500px) {
    .services-item {
        width: calc(100% / 2);
        min-width: 300px;
        height: max(40vh, 400px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 1000px) {
    .services-item {
        width: calc(100% / 2);
        height: max(40vh, 400px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 500px) {
    .services-item {
        width: calc(100%);
        height: max(40vh, 400px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1001px) {
    .services-item {
        width: calc(100% / 4);
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.services-item-inner-container {
    position: relative;
    width: 97%;
    height: 97%;
    cursor: pointer;
    border-radius: 20px;
    overflow: hidden;
    transition: 0.5s;
    background-color: rgb(0, 0, 0, 0.5);
}

@media (min-width: 1000px) {
    .services-item-inner-container:hover{
        background-color: rgb(0, 0, 0, 0.4);
        width: 98%;
        height: 98%;
        transition: 0.5s;
        box-shadow: 2px 2px 3px black;
    }
}

.services-item-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
}


.services-item-image-container img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
}

.services-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: white;
}

.services-item-name {
    width: 90%;
    text-decoration: none;
    font-size: 0.9em;
}