.request-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.request {
    width: 500px;
    min-width: 300px;
    max-width: 90vw;
    font-size: 28px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 700px) {
    .request {
        font-size: 24px;
    }
}


.request-title {
    font-size: 0.85em;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.request-description {
    font-size: 0.7em;
    margin-bottom: 30px;
    text-align: center;
}

.request-form {
    width: 100%;
    margin-bottom: 30px;
}

.request-header {
    font-size: 0.7em;
    margin-bottom: 5px;
}

.request-header span {
    font-size: 0.7em;
    margin-left: 5px;
}

.request-form-section {
    width: 100%;
    margin-bottom: 20px;
}

.request-name-inputs,.request-contact-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
}

.request-name-inputs input,.request-contact-inputs input {
    width: 100%;
    min-width: 300px;
    margin-right: 1%;
    margin-bottom: 20px;
    color: #464646;
    font-size: 0.6em;
}
 
.request-required-input {
    border: 1px solid #0F62AF;
    border-radius: 5px;
    font-size: 0.8em;
    color: #464646;
    padding: 5px 10px;
}

.request-error-input {
    border-radius: 5px;
    font-size: 0.8em;
    color: #464646;
    padding: 5px 10px;
    border: 1px solid #ff1900;
}

.request-form-section select {
    outline: none;
    border: none;
    border: 1px solid #0F62AF;
    border-radius: 5px;
    width: 250px;
    padding: 5px 10px;
    font-size: 0.7em;
    color: #464646;
}


.request-dayofweek span {
    font-size: 0.7em;
}


.request-dayofweek {
    display: flex;
    flex-direction: column;
}

.request-dayofweek label {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.request-checkbox {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #0F62AF;
} 

.request-checkbox-checked {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #0F62AF;
    background-color: #0F62AF;
}

.request-reason-inputs {
    width: 100%;
}

.request-reason-inputs textarea {
    width: 100%;
    color: #464646;
    font-size: 0.7em;
    resize: none;
    border-radius: 20px;
    padding: 15px;
}

.request-consent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.request-consent label {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6em;
}

.request-consent span {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.request-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    height: 50px;
}

.request-button-container button {
    border: none;
    width: 299px;
    height: 49px;
    color: white;
    font-size: min(0.8em, 30px);
    background-color: #0F62AF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
}

@media (min-width: 1000px) {
    .request-button-container button:hover{
        width: 300px;
        height: 50px;
        box-shadow: 1px 1px 2px black;
        transition: 0.2s;
    }
}


.request-notice{
    width: 100%;
    font-size: 0.7em;
    margin-bottom: 50px;
    text-align: center;
}

.request-another {
    text-decoration: none;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 50px;
}

.request-another button {
    border-radius: 100px;
    background-color: #0F62AF;
    color: white;
    border: none;
    font-size: 0.7em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 299px;
    height: 49px;
    transition: 0.2s;
}

@media (min-width: 1000px) {
    .request-another button:hover {
        width: 290px;
        height: 50px;
        box-shadow: 1px 1px 2px black;
        transition: 0.2s;
    }
}